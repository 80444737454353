import React from 'react';
import { Fireworks } from '@fireworks-js/react';
import ReactDOM from 'react-dom/client';

const Firework = () => {
  return (
    <>
      <Fireworks
        options={{
          opacity: 0.8,
          particles: 100,
          maxParticles: 100,
        }}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </>
  );
};

const node = document.getElementById('firework-background');
const data = JSON.parse(node.getAttribute('data'));

const root = ReactDOM.createRoot(node);

root.render(<Firework {...data} />);
